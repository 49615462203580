<template>
  <div>
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template v-if="hasModuleAccess">
        <Card class="px-3 py-3 mb-3" v-if="rangeSpreads.length">
          <div class="text-jet font-normal leading-5 text-base flex flex-col gap-2 bg-ghostWhite px-3 py-3 mb-5 rounded-md">
            <p>Your pay range spread defines the breath of your pay range, difference between the maximum and the minimum pay level, for a given pay range.</p>
            <p>It is adviceable to make pay ranges wide enough to accommodate different levels of experience, changes, and growth in responsibilities within a given level.</p>
            <p>The grades can be narrower for lower-level positions where incumbents tend to be early in their careers and more likely to be promoted faster. Wider ranges for mid- to late-career jobs will help you keep those employees on board because they don’t have to sacrifice competitive pay increases as they continue to deepen their experience and expertise in their current roles.</p>
          </div>
          <template v-slot:footer>
            <CardFooter
              :reloadcard="!isEditMode"
              @reload="onGetQuery"
              @sortType="onGetQuery({ sort: $event })"
              @searchResult="onGetQuery({ search: $event })"
            >
              <template v-slot:leftItems>
                <div @click="onEditRangeSpread">
                  <div class="shadow rounded-md p-2 cursor-pointer" v-if="!isEditMode">
                    <Icon icon-name="edit" size="s" />
                  </div>
                </div>
                <div class="flex justify-end items-center gap-3" v-if="isEditMode">
                  <div class="shadow rounded-md p-2 cursor-pointer" @click="onSubmit">
                    <Icon icon-name="icon-save-purple" size="s" />
                  </div>
                  <div class="shadow rounded-md p-2 cursor-pointer" @click="onEditRangeSpread">
                    <Icon icon-name="close" size="s" />
                  </div>
                </div>
              </template>
            </CardFooter>
          </template>
        </Card>
        <template v-if="rangeSpreads.length">
          <Table
            :headers="headers"
            :items="rangeSpreads"
            aria-label="range spread"
            class="w-full pt-4 mb-4"
            :has-number="true"
            :loading="isFetching"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.level">
                <span class="font-normal text-sm leading-5 text-darkPurple">
                  {{ item.data.level }}
                </span>
              </div>
              <div v-if="item.spread">
                <div class="font-normal text-sm leading-5 text-darkPurple">
                  <span v-if="!isEditMode">{{ item.data.spread }}%</span>
                  <TextInput
                    type="number"
                    :id="item.data.levelId"
                    v-model="rangeSpreads[item.index].spread"
                    v-if="isEditMode"
                    placeholder="%"
                    class="w-32"
                  />
                </div>
              </div>
            </template>
          </Table>
        </template>
        <template v-else>
          <div class="flex flex-col justify-center items-center gap-5 px-10 py-20">
            <Icon
              icon-name="paygrade_Illustration"
              size=""
              class-name="w-56 h-56"
            />
            <p class="font-normal text-base leading-5 text-jet text-center">
              An Overview of Range Spreads can be found here. This is empty now, but you can start adding range spreads to populate this area.
            </p>
            <div class="hover:bg-ghostWhite px-5 py-2 rounded-md cursor-pointer">
              <div class="flex justify-start items-center gap-2" @click="$router.push({ name: 'levels' })">
                <Icon icon-name="icon-plus" size="xs" />
                <span class="font-semibold text-base text-flame leading-5">
                  Add New Job Level
                </span>
              </div>
            </div>
          </div>
        </template>
      </template>
      <ErrorComponent v-else />
    </template>
  </div>
</template>

<script>
  import Table from "@scelloo/cloudenly-ui/src/components/table"
  import Card from "@scelloo/cloudenly-ui/src/components/card"
  import TextInput from "@scelloo/cloudenly-ui/src/components/text"
  import Loader from "@scelloo/cloudenly-ui/src/components/loader"
  import Icon from "@/components/Icon"
  import CardFooter from "@/components/CardFooter"
  import ErrorComponent from "@/modules/Admin/error403"

  export default {
    name: "RangeSpread",

    components: {
      Loader,
      Card,
      Table,
      TextInput,
      Icon,
      CardFooter,
      ErrorComponent
    },

    data() {
      return {
        isLoading: true,
        isEditMode: false,
        isFetching: false,
        hasModuleAccess: false,
        levels: [],
        rangeSpreads: [],
        headers: [
          { title: "job levels", value: "level" },
          { title: "range spread", value: "spread" },
        ],
      };
    },

    methods: {
      onGetQuery(queryParams){
        if(queryParams !== undefined){
          this.queryParams = {...this.queryParams, ...queryParams}
          this.getCompensationRangeSpread(this.queryParams)
        } else {
          this.queryParams = {}
          this.getCompensationRangeSpread()
        }
      },
      async onEditRangeSpread(){
        try {
          await this.$handlePrivilege('compensation', 'editRangeSpread')
          this.isEditMode = !this.isEditMode
        } catch(error) {
          const message = 'You do not have permission to perform this task'
          this.$toasted.error(message, { duration: 3000 })
        }
      },
      async getCompensationRangeSpread(payload){
        this.isFetching = true

        const sortRangeSpreadDesc = (items) => (
          items.sort((a, b) => parseFloat(a.level) - parseFloat(b.level))
        )

        await this.$_getCompensationRangeSpread(payload).then(({ data }) => {
          const rangeSpreads = data.data?.map((item) => ({
            level: item.level.name,
            levelId: item.level.levelId,
            spread: item.spread.spread ?? item.spread,
          }))

          if (rangeSpreads.length) {
            this.rangeSpreads = sortRangeSpreadDesc(rangeSpreads)
          }

          this.isFetching = false
        }).catch((response) => {
          this.$toasted.error(response.message, {duration: 3000})
          this.isFetching = false
        })
      },
      onSubmit(){
        this.isFetching = true

        const payload = {
          orgId: this.$orgId,
          ranges: this.rangeSpreads
        }

        this.$_postCompensationRangeSpread(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.$emit('submit', this.rangeSpreads)
          this.isFetching = false
          this.isEditMode = false
        }).catch(({ response }) => {
          this.$toasted.error(response.data.message, {duration: 3000})
          this.isFetching = false
        })
      },
    },

    async created(){
      this.isLoading = true

      try {
        await this.$handlePrivilege('compensation', 'accessRangeSpreadSettings')
        this.hasModuleAccess = true
        await this.getCompensationRangeSpread()
      } catch (error) {
        this.hasModuleAccess = false
      }

      this.isLoading = false
    },
  };
</script>
